import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I was scared of the terminal for a long time. For a while, I got by: there are plenty of apps to compile Sass files. But as I grew frustrated with the limitations of GUI solutions, I took the time to familiarize myself with the command line. As with any other tool I eventually went through `}<a parentName="p" {...{
        "href": "https://twitter.com/lowmess/status/903714807022469120"
      }}>{`several obsessive rounds`}</a>{` of tweaking and updating the terminal to my liking. Now, instead of being scared of the terminal, I don’t completely hate it. Here’s some of the ways I did that.`}</p>
    <h2>{`The Light at the End of the Tunnel`}</h2>
    <p>{`After going through all of this, this is what my terminal looks like. I prefer a minimal setup, but there’s a lot of features hidden underneath the simple exterior. Like a spooky skeleton.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.03125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'228\\'%20viewBox=\\'0%200%20400%20228\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M23%2015c-2%203-1%20179%200%20180%202%202%20352%202%20354%200%201-1%202-177%200-180-1-3-353-3-354%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "My Hyper terminal, showing some configuration",
          "title": "My Hyper terminal, showing some configuration",
          "src": "/static/5793872c2ca37e44670d9a485636d9fd/e5715/result.png",
          "srcSet": ["/static/5793872c2ca37e44670d9a485636d9fd/804b2/result.png 384w", "/static/5793872c2ca37e44670d9a485636d9fd/e5715/result.png 768w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Frameworks: Almost Certainly All You Need`}</h2>
    <p>{`Frameworks like `}<a parentName="p" {...{
        "href": "http://ohmyz.sh/"
      }}><inlineCode parentName="a">{`oh-my-zsh`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/sorin-ionescu/prezto"
      }}><inlineCode parentName="a">{`prezto`}</inlineCode></a>{` are awesome, and originally what sent me down this crazy path. However, over time I became dissatisfied with them. The problem—which, really, says much more about me than the frameworks—was that they’re simply `}<em parentName="p">{`too`}</em>{` powerful. There are too many functions and aliases and configuration options for me to easily wrap my mind around. So, rather than do the normal thing (read some docs and root around in the repo), I decided to spend a few hours to make a shell setup that has like 5% of the functionality.`}</p>
    <p>{`I concede that doing the exact opposite of this is not only completely justified, but probably much smarter as well.`}</p>
    <h2>{`Wheels Are for Sweet Bike Jumps, Not Reinvention`}</h2>
    <p>{`Fortunately, developer tooling is a realm where open source truly shines. There are literally dozens of FOSS projects that offer a more focused take on shell improvement. Chief among these is `}<a parentName="p" {...{
        "href": "https://brew.sh/"
      }}>{`Homebrew`}</a>{`, which helps me install `}<a parentName="p" {...{
        "href": "https://github.com/unixorn/awesome-zsh-plugins#plugins"
      }}>{`these other libraries`}</a>{` with just a few keystrokes. Here are a few of my other favorites.`}</p>
    <h3>{`zsh-git-prompt`}</h3>
    <p>{`The first thing that caused me to switch from `}<inlineCode parentName="p">{`bash`}</inlineCode>{` to `}<inlineCode parentName="p">{`zsh`}</inlineCode>{` and to use frameworks like `}<inlineCode parentName="p">{`oh-my-zsh`}</inlineCode>{` was the ability to `}<a parentName="p" {...{
        "href": "https://github.com/olivierverdier/zsh-git-prompt"
      }}>{`see my `}<inlineCode parentName="a">{`git`}</inlineCode>{` status right in my prompt`}</a>{`. I later realized that it was entirely possible to achieve without using either of those things, but it’s a still a good segue. This appropriately-named project helps make that easy, and after `}<a parentName="p" {...{
        "href": "https://github.com/lowmess/dotfiles/blob/e7bc15f22ba756a0106285229e0c930ee4f6dd0a/.zshrc#L24-L41"
      }}>{`some quick configuration`}</a>{`, I was able to get my prompt looking just as I like it.`}</p>
    <h3>{`zsh-syntax-highlighting`}</h3>
    <p>{`Another convenient thing the frameworks bring is `}<a parentName="p" {...{
        "href": "https://github.com/zsh-users/zsh-syntax-highlighting"
      }}>{`syntax highlighting`}</a>{`. I can’t count the amount of times highlighting has prevented me from typing `}<inlineCode parentName="p">{`gti`}</inlineCode>{` (a type of car) instead of `}<inlineCode parentName="p">{`git`}</inlineCode>{` (a cool and good technology). Just source this also-appropriately-named package at the end of your `}<inlineCode parentName="p">{`.zshrc`}</inlineCode>{` and be dazzled at the pretty, prettily-informative colors.`}</p>
    <h3>{`hub`}</h3>
    <p>{`I am working in `}<a parentName="p" {...{
        "href": "https://github.com"
      }}>{`GitHub`}</a>{` repos basically all day. And while those repos work just fine with vanilla `}<inlineCode parentName="p">{`git`}</inlineCode>{`, GitHub has a helper library to make working with repos on their service a little easier. `}<a parentName="p" {...{
        "href": "https://hub.github.com"
      }}><inlineCode parentName="a">{`hub`}</inlineCode></a>{` allows you to do basically anything you can do on the GitHub webapp right from your terminal. My favorite feature is `}<inlineCode parentName="p">{`browse`}</inlineCode>{`, which allows you to quickly open up a repo’s GitHub page from the comfort of your keyboard instead of manually opening a tab like a jackass.`}</p>
    <h3>{`z`}</h3>
    <p>{`One of the first things I did when I started customizing my terminal was to set up custom aliases to jump into directories from anywhere. Judging by some of my coworkers setups, this is far from uncommon. Luckily, `}<a parentName="p" {...{
        "href": "https://github.com/rupa/z"
      }}><inlineCode parentName="a">{`z`}</inlineCode>{` makes that irrelevant`}</a>{`. Instead of setting up new aliases for every directory, just `}<inlineCode parentName="p">{`cd`}</inlineCode>{` into it one time, and then you can `}<inlineCode parentName="p">{`z [name of dir]`}</inlineCode>{` into it from anywhere. What sets `}<inlineCode parentName="p">{`z`}</inlineCode>{` apart from similar libraries like `}<a parentName="p" {...{
        "href": "https://github.com/wting/autojump"
      }}><inlineCode parentName="a">{`autojump`}</inlineCode></a>{` is a concept called “frecency”. Basically, if you’ve been accessing a directory a lot recently, `}<inlineCode parentName="p">{`z`}</inlineCode>{` will score that directory higher than other similarly-named ones. Plus it sounds like the name of a really bad action movie, and that’s cool.`}</p>
    <h2>{`Like a \\$3,000 Custom-Tailored Fingerless Glove`}</h2>
    <p>{`Now that we have all of the libraries we want installed, we can begin to craft aliases and functions to mold our development environment to our own unique sensibilities. One of the major benefits I’ve found of doing this over using frameworks is that any additional feature or effeciency in my shell is one that I introduced, which means that I actually know about it and will use it. Probably. Honestly I give it like a 65% chance.`}</p>
    <p>{`These can be simple:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# because typing an extra 7 chars is just too much work
alias r="yarn run"
`}</code></pre>
    <p>{`These can be fun:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# yes, dude, the weather is fun
function weather() {
  curl -H "Accept-Language: \${LANG%_*}" wttr.in/"\${1:-Tempe}"?0q
}
`}</code></pre>
    <p>{`But the most important factor is that they’re mine (or, in your case, yours).`}</p>
    <h2>{`The Real Terminal Is the Friends We Made Along the Way`}</h2>
    <p>{`I am still the absolute furthest thing from an expert in this realm. But this process has made me a lot more comfortable on the command line. I don’t really have a great way to wrap this up, so I’ll leave you with this:`}</p>
    <ol>
      <li parentName="ol">{`If you `}<em parentName="li">{`are`}</em>{` an expert in this realm, come make fun of `}<a parentName="li" {...{
          "href": "https://github.com/lowmess/dotfiles"
        }}>{`my bad dotfiles repo`}</a>{`. If you’re not an expert you can look at in awe or whatever.`}</li>
      <li parentName="ol">{`Again, this entire process could have been avoided by just reading some docs. This was way more fun.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      